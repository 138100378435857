import React, { useRef, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "./Char.css";
import chars from "./chars.json";

type Char = {
  id: string;
  name: string;
  standsFor?: string;
  desc: string;
  citeText: string;
  citeUrl: string;
};

const CharIconItem: React.FC<{
  char: Char;
  disabled: boolean;
  onClick: () => void;
}> = (props) => {
  return (
    <li className="char-icon__item">
      <button
        className="char-icon__button"
        disabled={props.disabled}
        onClick={props.onClick}
      >
        <img src={`./images/icon_${props.char.id}.png`} alt={props.char.name} />
      </button>
    </li>
  );
};

type CharIconListProps = {
  chars: Char[];
  currentChar: Char;
  setCurrentChar: React.Dispatch<React.SetStateAction<Char>>;
};

const CharIconList: React.FC<CharIconListProps> = (props) => {
  return (
    <ul className="char-icon__list">
      {props.chars.map((char, index) => (
        <CharIconItem
          key={index}
          char={char}
          disabled={char === props.currentChar}
          onClick={() => props.setCurrentChar(char)}
        />
      ))}
    </ul>
  );
};

const CharIcon: React.FC<CharIconListProps> = (props) => {
  return (
    <section className="char-icon">
      <CharIconList
        chars={props.chars}
        currentChar={props.currentChar}
        setCurrentChar={props.setCurrentChar}
      />
    </section>
  );
};

const CharFigFrontBack: React.FC<{ charId: string; charName: string }> = (
  props
) => {
  const [isBack, setIsBack] = useState(false);
  const frontRef = useRef(null);
  const backRef = useRef(null);
  const nodeRef = isBack ? backRef : frontRef;
  return (
    <>
      <SwitchTransition>
        <CSSTransition
          key={isBack ? "b" : "f"}
          nodeRef={nodeRef}
          timeout={200}
          // addEndListener={(node, done) =>
          //   node.addEventListener("transitionend", done, false)
          // }
          classNames="char-fig__img"
        >
          <button onClick={() => setIsBack(!isBack)}>
            <img
              ref={nodeRef}
              className="char-fig__img"
              src={`./images/fig_${props.charId}_${isBack ? "b" : "f"}.png`}
              alt={`${props.charName}の${isBack ? "後ろ" : "前"}`}
            />
          </button>
        </CSSTransition>
      </SwitchTransition>
    </>
  );
};

const CharFigDraft: React.FC<{ charId: string; charName: string }> = (
  props
) => (
  <img
    className="char-fig__img"
    src={`./images/draft_${props.charId}.jpg`}
    alt={`${props.charName}の原案`}
  />
);

const CharFig: React.FC<{ charId: string; charName: string }> = (props) => {
  const figTypes = [
    { id: "fb", text: "彩色", elem: CharFigFrontBack },
    { id: "draft", text: "原案", elem: CharFigDraft },
  ];
  const [currentFigType, setCurrentFigType] = useState(figTypes[0]);
  return (
    <div className="char-fig">
      <SwitchTransition>
        <CSSTransition
          key={currentFigType.id}
          timeout={200}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="char-fig__type"
        >
          <div className="char-fig__type">
            <currentFigType.elem {...props} />
          </div>
        </CSSTransition>
      </SwitchTransition>
      <ul className="char-fig__list">
        {figTypes.map((figType, index) => (
          <li key={index} className="char-fig__item">
            <button
              className="char-fig__button"
              disabled={figType.id === currentFigType.id}
              onClick={() => setCurrentFigType(figType)}
            >
              {figType.text}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

const CharName: React.FC<{ ja: string; en?: string }> = (props) => (
  <h3 className="char-name">
    <div className="char-name__ja">{props.ja}</div>
    {props.en !== undefined && (
      <div className="char-name__en">
        {props.en
          .split("**")
          .map((part, index) =>
            index % 2 === 0 ? (
              <React.Fragment key={index}>{part}</React.Fragment>
            ) : (
              <b key={index}>{part}</b>
            )
          )}
      </div>
    )}
  </h3>
);

const CharCite: React.FC<{ url: string; text: string }> = (props) => (
  <div className="char-cite">
    <a href={props.url}>{props.text}</a>
  </div>
);

const CharDetail: React.FC<{ char: Char }> = (props) => (
  <section className="char-detail">
    <CharName ja={props.char.name} en={props.char.standsFor} />
    <p className="char-desc">{props.char.desc}</p>
    <CharCite url={props.char.citeUrl} text={props.char.citeText} />
    <CharFig charId={props.char.id} charName={props.char.name} />
  </section>
);

const CharSection: React.FC = () => {
  const [currentChar, setCurrentChar] = useState(chars[0]);

  return (
    <React.Fragment>
      <CharIcon
        chars={chars}
        currentChar={currentChar}
        setCurrentChar={setCurrentChar}
      />
      <SwitchTransition>
        <CSSTransition
          key={currentChar.id}
          timeout={500}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="char-detail"
        >
          <CharDetail char={currentChar} />
        </CSSTransition>
      </SwitchTransition>
    </React.Fragment>
  );
};

export default CharSection;
