import React from "react";
import "./Comics.css";

const Comics: React.FC = () => (
  <>
    <p className="comics__ad">コミックス第1巻、好評発売中！</p>
    <p className="comics__author">著: 潮</p>
    <ul className="comics__comic-list">
      <li className="comics__comic-item">
        <img className="comic__image" src="./images/comics.jpg" alt="" />
        <span className="comic__title">バートちゃんはエンコーダ 1</span>
      </li>
    </ul>
    <p className="comics__note">※エイプリルフールにおけるハルシネーションです</p>
  </>
);

export default Comics;
