import React from "react";
import { Outlet } from "react-router-dom";
import "./root.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Root: React.FC = () => (
  <>
    <Header />
    <div id="detail">
      <Outlet />
    </div>
    <Footer />
  </>
);

export default Root;
