import React from "react";
import "./Footer.css";

type ShareItemProps = {
  id: string;
  name: string;
  url: string;
};

const ShareItem: React.FC<ShareItemProps> = (props) => (
  <li className="share__item">
    <a href={props.url}>
      <img src={`./images/share_${props.id}.png`} alt={props.name} />
    </a>
  </li>
);

const ShareList: React.FC<{ services: ShareItemProps[] }> = (props) => (
  <ul className="share__list">
    {props.services.map((service, index) => (
      <ShareItem key={index} {...service} />
    ))}
  </ul>
);

const services: ShareItemProps[] = [
  {
    id: "x",
    name: "X",
    url: "https://twitter.com/intent/tweet?text=%E3%83%90%E3%83%BC%E3%83%88%E3%81%A1%E3%82%83%E3%82%93%E3%81%9F%E3%81%A1%E3%81%AE%E6%9C%80%E5%85%88%E7%AB%AF%E3%81%AA%E6%97%A5%E5%B8%B8%E3%81%AB%E3%81%AF%E6%96%B0%E8%A6%8F%E6%80%A7%E3%81%8C%E3%81%84%E3%81%A3%E3%81%B1%E3%81%84%EF%BC%9F%E6%90%8D%E5%A4%B1%E9%96%A2%E6%95%B0%E7%99%BA%E6%95%A3%E5%BF%85%E8%87%B3%E3%81%AE%E4%BA%8B%E5%89%8D%E5%AD%A6%E7%BF%92%E6%B8%88%E3%81%BF%E3%83%A2%E3%83%87%E3%83%AB%E7%BE%A4%E5%83%8F%E5%8A%87%EF%BC%81&url=https://bert-chan.jp/&hashtags=%E3%83%90%E3%83%BC%E3%83%88%E3%81%A1%E3%82%83%E3%82%93",
  },
  {
    id: "facebook",
    name: "Facebook",
    url: "https://www.facebook.com/sharer/sharer.php?u=https://bert-chan.jp/",
  },
  {
    id: "line",
    name: "LINE",
    url: "https://social-plugins.line.me/lineit/share?url=https://bert-chan.jp/",
  },
];

const Footer: React.FC = () => (
  <footer>
    <div className="share">
      <h3 className="share__header">シェア</h3>
      <ShareList services={services} />
    </div>
    <p className="copy">&copy; 2024 潮</p>
  </footer>
);

export default Footer;
