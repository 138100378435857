import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.css";

/** https://dev.to/mindactuate/scroll-to-anchor-element-with-react-router-v6-38op */
function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "auto", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location]);
}

const Title: React.FC = () => (
  <h1>
    <Link to="/">
      <img
        className="title__img"
        src="./images/logo_h.png"
        alt="バートちゃんはエンコーダ"
      />
    </Link>
  </h1>
);

type NavItemProps = { id: string; text: string };

const NavItem: React.FC<NavItemProps> = (props) => (
  <li className="nav__item">
    <Link to={props.id === "yonkoma" ? "yonkoma" : "/#" + props.id}>
      {props.text}
    </Link>
  </li>
);

type NavListProps = { navItems: NavItemProps[] };

const NavList: React.FC<NavListProps> = (props) => (
  <ul className="nav__list">
    {props.navItems.map((navItem, index) => (
      <NavItem key={index} {...navItem} />
    ))}
  </ul>
);

const NavDefault: React.FC<NavListProps> = (props) => (
  <div className="nav__default">
    <NavList {...props} />
  </div>
);

const NavHanburger: React.FC<NavListProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav className="nav__hamburger">
      <button className="nav__toggle" onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? "×" : "≡"}
      </button>
      {isOpen && <NavList {...props} />}
    </nav>
  );
};

const Nav: React.FC<NavListProps> = (props) => (
  <nav>
    <NavDefault {...props} />
    <NavHanburger {...props} />
  </nav>
);

const Header: React.FC = () => {
  const navItems: NavItemProps[] = [
    { id: "abs", text: "あらすじ" },
    { id: "char", text: "キャラ" },
    { id: "yonkoma", text: "4コマ" },
    { id: "staff", text: "スタッフ" },
    { id: "comics", text: "コミックス" },
  ];
  
  ScrollToAnchor();

  return (
    <header>
      <Title />
      <Nav navItems={navItems} />
    </header>
  );
};

export default Header;
