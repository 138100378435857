import "./Hero.css";

function Hero() {
  return (
    <div id="hero">
      <img src="./images/hero.png" alt="バートちゃんはエンコーダ" />
      <img src="./images/logo.png" alt="バートちゃんはエンコーダ" />
    </div>
  );
}

export default Hero;
