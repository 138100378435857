import React from "react";
import { useInView } from "react-intersection-observer";
import "./MainSection.css";

export type MainSectionProps = {
  id: string;
  header: string;
  children: React.ReactNode;
};

const MainSection: React.FC<MainSectionProps> = (props) => {
  const [ref, inView, entry] = useInView({
    rootMargin: "-200px",
    triggerOnce: true,
  });

  return (
    <section
      ref={ref}
      id={props.id}
      className={"main__section" + (inView ? " main__section--in-view" : "")}
    >
      <h2 className="section__title">{props.header}</h2>
      {props.children}
      <span className="section__sep">[SEP]</span>
    </section>
  );
};

export default MainSection;
