import React from "react";
import "./Comment.css";

const Staff: React.FC = () => (
  <React.Fragment>
    <table className="staff__table">
      <tbody>
        <tr>
          <td>原案</td>
          <td>潮</td>
        </tr>
        <tr>
          <td>作画</td>
          <td>潮</td>
        </tr>
        <tr>
          <td>キャラクターデザイン</td>
          <td>潮</td>
        </tr>
      </tbody>
    </table>
    <section className="comment">
      <h3 className="comment__h">コメント</h3>
      <p className="comment__p">
        私の自然言語処理はTransformerとともにありました。GPTがGPT-2やGPT-3に進化したり、BERTがRoBERTaやDeBERTaに派生したり。BARTとT5が時を同じくして登場したこともありました。
      </p>
      <p className="comment__p">
        汎用言語モデルを擬人化してしまったのは、擬人化が好きだからにほかなりません。船の擬人化で育った私は、刀の擬人化が好きな同期や馬の擬人化が好きな同期と、隙あらば研究室で盛り上がっていました。一方『バートちゃんはエンコーダ』は、自然言語処理におけるBERT時代のアーカイブだとも考えています。新たなモデルが提案されたかと思えば、ユニークな名前をもって世界に公開され、リーダボードの変動に一喜一憂する。そんな日々の興奮を覚えておきたかったのです。
      </p>
      <p className="comment__p">
        ChatGPTの普及によって、自然言語処理は新たなパラダイムへと移りゆくことでしょう。その背景には、TransformerやBERTといった魅力的な技術があったことを忘れないでください。
      </p>
    </section>
  </React.Fragment>
);

export default Staff;
