import React from "react";
import Container from "../components/Container";
import MainSection from "../components/MainSection";
import Yonkoma from "../components/Yonkoma";

const YonkomaRoute: React.FC = () => (
  <main>
    <Container>
      <MainSection id="yonkoma" header="4コマ">
        <Yonkoma />
      </MainSection>
    </Container>
  </main>
);

export default YonkomaRoute;
