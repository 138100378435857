import React from "react";
import ReactDOM from "react-dom/client";
import "destyle.css";
import "./index.css";
import Yonkoma from "./routes/yonkoma";
import Hero from "./components/Hero";
import Container from "./components/Container";
import MainSection from "./components/MainSection";
import AbsContent from "./components/Abs";
import CharContent from "./components/Char";
import CommentContent from "./components/Comment";
import ComicsContent from "./components/Comics";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";

const RootChild: React.FC = () => {
  const mainSections = [
    { id: "abs", header: "あらすじ", children: <AbsContent /> },
    { id: "char", header: "キャラ", children: <CharContent /> },
    { id: "staff", header: "スタッフ", children: <CommentContent /> },
    { id: "comics", header: "コミックス", children: <ComicsContent /> },
  ];

  return (
    <>
      <Hero />
      <main>
        <Container>
          {mainSections.map((section, index) => (
            <MainSection key={index} {...section} />
          ))}
        </Container>
      </main>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <RootChild /> },
      { path: "yonkoma", element: <Yonkoma /> },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
