import React, { useEffect, useState } from "react";
import "./Yonkoma.css";
import chars from "./chars.json";
import yonkomas from "./yonkomas.json";

type ToggleCharType = Pick<(typeof chars)[number], "id" | "name"> & {
  isActive: boolean;
};

const ToggleCharItem: React.FC<{
  toggleChar: ToggleCharType;
  onClick: () => void;
}> = (props) => (
  <li className="toggle__item">
    <button
      className={
        "toggle__button" +
        (props.toggleChar.isActive ? " toggle__button--active" : "")
      }
      onClick={props.onClick}
    >
      {props.toggleChar.name}
    </button>
  </li>
);

const ToggleCharList: React.FC<{
  toggleChars: ToggleCharType[];
  setToggleChars: React.Dispatch<React.SetStateAction<ToggleCharType[]>>;
}> = (props) => (
  <ul className="toggle__list">
    {props.toggleChars.map((yonkomaChar, index) => (
      <ToggleCharItem
        key={index}
        toggleChar={yonkomaChar}
        onClick={() => {
          props.setToggleChars(
            props.toggleChars.map((currentYonkomaChar) =>
              currentYonkomaChar.id === yonkomaChar.id
                ? {
                    ...currentYonkomaChar,
                    isActive: !currentYonkomaChar.isActive,
                  }
                : currentYonkomaChar
            )
          );
        }}
      />
    ))}
  </ul>
);

type YonkomaType = (typeof yonkomas)[number];

const YonkomaItem: React.FC<{
  yonkoma: YonkomaType;
  setDialogYonkoma: React.Dispatch<React.SetStateAction<YonkomaType | null>>;
}> = (props) => (
  <li className="yonkoma__item">
    {/* <a href={`/images/4/${props.yonkoma.id}.jpg`}> */}
    <button onClick={() => props.setDialogYonkoma(props.yonkoma)}>
      <img
        className="yonkoma__img"
        src={`/images/4/${props.yonkoma.id}.jpg`}
        alt={props.yonkoma.title}
      />
    </button>
    {/* </a> */}
  </li>
);

const YonkomaList: React.FC<{
  yonkomas: YonkomaType[];
  setDialogYonkoma: React.Dispatch<React.SetStateAction<YonkomaType | null>>;
}> = (props) => (
  <ul className="yonkoma__list">
    {props.yonkomas.map((yonkoma, index) => (
      <YonkomaItem
        key={index}
        yonkoma={yonkoma}
        setDialogYonkoma={props.setDialogYonkoma}
      />
    ))}
  </ul>
);

const YonkomaImageDialog: React.FC<{
  yonkoma: YonkomaType | null;
  setDialogYonkoma: React.Dispatch<React.SetStateAction<YonkomaType | null>>;
}> = (props) => (
  <dialog open={props.yonkoma !== null}>
    <div className="dialog__container">
      <section className="dialog__yonkoma">
        <img
          className="dialog__image"
          src={`/images/4/${props.yonkoma?.id}.jpg`}
          alt={props.yonkoma?.title}
        />
        <button
          className="dialog__close"
          onClick={() => props.setDialogYonkoma(null)}
        >
          ×
        </button>
      </section>
    </div>
  </dialog>
);

const Yonkoma: React.FC = () => {
  const [toggleChars, setToggleChars] = useState<ToggleCharType[]>([]);
  const [currentYonkomas, setCurrentYonkomas] = useState<YonkomaType[]>([]);

  const [dialogYonkoma, setDialogYonkoma] = useState<YonkomaType | null>(null);

  useEffect(() => {
    setToggleChars(
      chars.map((char) => ({ id: char.id, name: char.name, isActive: false }))
    );
  }, []);

  useEffect(() => {
    setCurrentYonkomas(yonkomas);
  }, []);

  useEffect(() => {
    setCurrentYonkomas(
      yonkomas.filter((yonkoma) =>
        // すべてのキャラについて
        toggleChars.every(
          // ボタンが押されていたら、含まれているか判定
          (currentYonkomaChar) =>
            !currentYonkomaChar.isActive ||
            yonkoma.charIds.some((charId) => charId === currentYonkomaChar.id)
        )
      )
    );
  }, [toggleChars]);

  return (
    <>
      <p>バートちゃんたちの新規性あふれる最先端な日常を描いた4コマはコチラ。</p>

      <div className="toggle">
        <div className="toggle__prompt">キャラで絞り込み:</div>
        <ToggleCharList
          toggleChars={toggleChars}
          setToggleChars={setToggleChars}
        />
      </div>
      <YonkomaList
        yonkomas={currentYonkomas}
        setDialogYonkoma={setDialogYonkoma}
      ></YonkomaList>
      <YonkomaImageDialog
        yonkoma={dialogYonkoma}
        setDialogYonkoma={setDialogYonkoma}
      />
    </>
  );
};

export default Yonkoma;
