import React from "react";
import "./Abs.css";

const RubyAnnotation: React.FC<{ text: string; annotation: string }> = (
  props
) => (
  <ruby>
    {` ${props.text} `}
    <rp>(</rp>
    <rt>{props.annotation}</rt>
    <rp>)</rp>
  </ruby>
);

const Abs: React.FC = () => (
  <React.Fragment>
    <p className="abs__p">
      <RubyAnnotation text="Q" annotation="クエリ" />と
      <RubyAnnotation text="K" annotation="キー" />、
      <RubyAnnotation text="V" annotation="バリュー" />
      からなるマルチヘッドアテンションをもとにしたバートちゃんたち。そんなバートちゃんたちの
      <RubyAnnotation text="SOTA" annotation="最先端" />
      な日常には、新規性がいっぱい！？
    </p>
    <p className="abs__p">
      バートちゃんはエンコーダだけど、ジーピーティーちゃんはデコーダ？もう一人のバートちゃんはエンコーダデコーダだし、ティーファイブちゃんもそう？
    </p>
    <p className="abs__p">損失関数発散必至の、事前学習済みモデル群像劇！！</p>
  </React.Fragment>
);

export default Abs;
